import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Heading, Flex, Box, Text } from 'rebass';
import L from 'leaflet';
import { Map, TileLayer, LayersControl, Marker, Popup } from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import Section from '../components/Section';
import Form from '../components/Form';

export const query = graphql`
	query {
    allContentfulSection(filter: { SectionName: { eq: "Kontakt" } }) {
				nodes {
					header
          SectionName
          contact {
                mobileNumber
                telephoneNumber
                mainEmailAddress
                localization
                popupMessage
            }
				}
		}
	}
`;

const state = { 
  lat: 49.944566,
  lng: 21.751090,
  zoom: 15,
}
const pos = [
  49.944566, 21.751090
]
export class LeafMap extends Component {
  componentDidMount() {
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
      iconRetinaUrl : require('leaflet/dist/images/marker-icon-2x.png'),
      iconUrl       : require('leaflet/dist/images/marker-icon.png'),
      shadowUrl     : require('leaflet/dist/images/marker-shadow.png'),
    });
  }

  render() {
    const {BaseLayer} = LayersControl || {};
    return (
      <div ref={this.mapWrapperRef} className="map-wrapper">
        {(typeof window !== 'undefined') ? (
          <Map
            center={pos}
            scrollWheelZoom={false}
            touchZoom={false}
            noMoveStart
            zoom={state.zoom}
            ref={this.mapRef}
            style={{height: '540px', width: '100%'}}
          >
            <LayersControl position="topright">
              <BaseLayer checked name="OpenStreetMap.BlackAndWhite">
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                />
              </BaseLayer>
            </LayersControl>
            <Marker position={pos}>
                <Popup>{this.props.popupMessage}</Popup>
            </Marker>
          </Map>
        ) : null}
      </div>
    );
  }
}

const centerHorizontally = { marginRight: 'auto', marginLeft: 'auto' };

const Contact = () => (
  <StaticQuery
    query={query}
    render={({ allContentfulSection: {nodes:[{header,
       SectionName, contact: { mobileNumber,
        telephoneNumber,
        mainEmailAddress,
        localization,
        popupMessage, }}]}}) => {
  return (
    <Section.Container id={SectionName} containerStyles={{minHeight: 'auto', maxWidth: '100%'}}> 
      <>
        <Flex
          textAlign="center"
          flexWrap="wrap"
        >
          <Box flex="1 1 50%">
            <Heading
              as="h1"
              color="secondary"
              fontSize={[4, 5, 6]}
              mb={[3, 4, 4]}
            >
        {header}
            </Heading>
            <Heading
              as="h2"
              color="primaryFont"
              textAlign="center"
              fontSize={[3,3,3]}
              style={centerHorizontally}
              mb={[4, 3, '58px']}
            >
              <Text
                key={1}
                fontWeight="normal"
                style={centerHorizontally}
                mb={[3, 4, 4]}
              >
          {localization}
              </Text>
              <Text
                fontSize="15px"
                letterSpacing="normal"
                lineHeight="1.875em"
                fontWeight="normal"
                style={centerHorizontally}
                key={2}
              >
          {mainEmailAddress}
              </Text>
              <Text
                fontSize="15px"
                letterSpacing="normal"
                lineHeight="1.875em"
                fontWeight="normal"
                style={centerHorizontally}
                key={3}
              >
          {telephoneNumber}, {mobileNumber}
              </Text>
            </Heading>
          </Box>
          <Box 
            flexBasis="30em"
            minWidth="50%"
            mx="auto"
          >
            <Form />
          </Box>
        </Flex>
        <Box width="100%" py="5">
          <LeafMap popupMessage={popupMessage}/>
        </Box>
      </>
    </Section.Container>
)}}
  />
);

export default Contact;
