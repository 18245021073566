import React from 'react';
import { Heading, Image, Text } from 'rebass';
import { StaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';

export const query = graphql`
	query {
		allImageSharp {
			edges {
				node {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	}
`;

const ServiceElement = ({ imageSrc, service, description }) => (
  <Fade cascade>
    <div>
      <Image src={imageSrc} alt="img" mt={[ '169px', '169px', '107px' ]} pb={[ 4, 4, 5 ]} />
      <Heading as="h2" color="secondary" fontSize={[ 3, 3, 3 ]} mb={[ 4, 3, '58px' ]}>
        <Text
          width={[ 300, 400, 400 ]}
          pb={[ 3, 3, 3 ]}
          letterSpacing="normal"
          lineHeight="1.575em"
          fontWeight="lighter"
          key={1}
        >
          {service}
        </Text>
        <Text
          width={[ 300, 400, 400 ]}
          fontSize={[ 2, 2, 2 ]}
          letterSpacing="normal"
          color="primaryFont"
          lineHeight="1.575em"
          fontWeight="lighter"
        >
          {description}
        </Text>
      </Heading>
    </div>
  </Fade>
);

export default ServiceElement;
