import React from 'react';
import { Heading, Link, Image, Flex, Box, Text, Button } from 'rebass';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';

const SliderElement = ({ occupation, name, description, phone }) => (
	<Box pl={[ 0, 6, 6 ]} pr={[ 0, 6, 6 ]}>
		<Fade bottom>
			<Text fontSize="18px" letterSpacing="normal" mb={40} lineHeight="1.875em" fontWeight="normal">
				{occupation}
			</Text>
		</Fade>
		<Fade bottom>
			<Text
				fontSize="18px"
				color="secondary"
				letterSpacing="normal"
				mb={35}
				lineHeight="1.41em"
				fontWeight="normal"
			>
				{name}
			</Text>
		</Fade>
		<Fade bottom>
			<Text fontSize="15px" letterSpacing="normal" mb={35} lineHeight="1.875em" fontWeight="normal">
				{description}
			</Text>
		</Fade>
		{phone && <Fade bottom>
			<Button bg="secondary" color="primary">
				✆ {phone}
			</Button>
		</Fade>}
	</Box>
);

export default SliderElement;
