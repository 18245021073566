import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Slide from "react-reveal/Slide";
import styled, { css } from "styled-components";
import makeCarousel from "react-reveal/makeCarousel";
import Fade from "react-reveal/Fade";
import { Heading, Box, Text } from "rebass";
import Section from "../components/Section";
import SliderElement from "../components/SliderElement";

const width = "100%"; const height="300px";
const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: ${width};
`;
const Children  = styled.div`
  width: ${width};
  position: relative;
  padding: 0 5em;
  height: ${height};
`;
const Arrow = styled.div`
  text-shadow: 1px 1px 1px #fff;
  z-index: 100;
  line-height: ${height};
  text-align: center;
  position: absolute;
  top: 0;
  width: 10%;
  font-size: 3em;
  cursor: pointer;
  user-select: none;
  ${props => props.right ? css`left: 90%;` : css`left: 0%;`}
`;
const Dot = styled.span`
  font-size: 1.5em;
  cursor: pointer;
  text-shadow: 1px 1px 1px #fff;
  user-select: none;
`;
const Dots = styled.span`
  display: flex;
  justify-content: center;
  text-align: center;
  width: ${width};
  z-index: 100;
`;
const centerHorizontally = { marginRight: "auto", marginLeft: "auto" };

const CarouselUI = ({ position, total, handleClick, children, showArrows = true }) => (
  <Container>
    <Children>
      {children}
    </Children>
    {showArrows ? 
    ( 
      <>
        <Arrow onClick={handleClick} data-position={position - 1}>{"<"}</Arrow>
        <Arrow right onClick={handleClick} data-position={position + 1}>{">"}</Arrow>
      </>
    ): null
    }
     
    <Dots>
      {Array(...Array(total)).map( (val, index) => (
        <Dot key={index} onClick={handleClick} data-position={index}>
          {index === position ? "● " : "○ " }
        </Dot>
      )
      )}
    </Dots>
  </Container>
);
const Carousel = makeCarousel(CarouselUI);
const settings = {
  dots: true,
  infinite: true,
  speed: 300,
};

const Caps = styled.div`
text-transform: uppercase;
`

export const query = graphql`
	query {
    allContentfulSection(filter: { SectionName: { eq: "Zespół" } }) {
				nodes {
					header
					h2
					h3 {
						h3
					}
          SectionName
          content {
            employee {
              description
              name
              occupation
              phone
            }
        }
				}
		}
	}
`;

const TeamPage = ({items}) => (
  <StaticQuery
    query={query}
    render={({ allContentfulSection, allContentfulSection: {nodes:[{header,
      h2, SectionName, content: { employee }}]} }) => {
      return (
        <Section.Container id={SectionName} containerStyles={{ padding: "0em 1em" }}>
        <>
          <Box py={[5, 0, 0]}>
            <Fade bottom>
              <Heading
                textAlign="center"
                as="h1"
                color="secondary"
                fontSize={[ 5, 5, 5 ]}
                mb={[ 3, 4, 4 ]}
              >
                <Caps>{header}</Caps>
              </Heading>
              <Heading as="h2" color="primaryFont" textAlign="center" style={centerHorizontally}>
                <Text
                  variant="caps"
                  width={[ 300, 500 ]}
                  color="primaryFont"
                  fontWeight="normal"
                  style={centerHorizontally}
                  fontSize={[ 3, 3, 3 ]}
                  mb={[ 3, 4, 4 ]}
                >
                  {h2}
                </Text>
              </Heading>
            </Fade>
          </Box>
          <Carousel defaultWait={5000} maxTurns={Number.POSITIVE_INFINITY} showArrows={false}>
            {employee.length && employee.map((item, index )=>(
              <Slide right key={index}>       
                <SliderElement
                  {...item}
                />
              </Slide>
            )
          )}
          </Carousel>
        </>
      </Section.Container>
)}}
  />
);

export default TeamPage;
