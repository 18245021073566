import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { Text, Box, Link } from 'rebass';
import Fade from 'react-reveal/Fade';

const FooterContainer = styled.div`
	min-width: 320px;
	max-width: 1366px;
	display: flex;
	flex: 0 1 auto;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: auto;
`;

const TextFooter = styled(Text)`
  color: ${(props) => props.theme.colors.background};
  width: 100%;
  & a {
    color: ${(props) => props.theme.colors.background};
  }
`;

const Footer = () => (
  <StaticQuery
    query={graphql`
			query FooterQuery {
				allContentfulCompanyInfo {
      nodes {
        mobileNumber
        telephoneNumber
				localization
      }
    }
			}
		`}
    render={(data) => {
			const {allContentfulCompanyInfo: {nodes: [{mobileNumber, telephoneNumber, localization}]}} = data;
			return (
  <Box pb={3} textAlign="center" backgroundColor="primaryDark" as="footer">
    <FooterContainer>
      <Fade left>
        <TextFooter fontSize={[ 2, 3 ]}>
								©2019 LISBUD, 
          {' '}
          {localization}
          <div>
✆
            {' '}
            {telephoneNumber}
            {' '}
📱
            {' '}
            {mobileNumber}
          </div>
        </TextFooter>
      </Fade>
    </FooterContainer>
  </Box>
			);
		}}
  />
);

export default Footer;
