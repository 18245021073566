import React from 'react';
import Layout from '../components/Layout';
import Landing from '../sections/Landing';
import Team from '../sections/Team';
import Info from '../sections/Info';
import Header from '../components/Header';
import Contact from '../sections/Contact';
import Services from '../sections/Services';
import Footer from '../components/Footer';

const IndexPage = () => (
  <Layout>
    <Header />
    <Landing />
    <Info />
    <Team />
    <Services />
    <Contact />
    <Footer />
  </Layout>
);

export default IndexPage;
