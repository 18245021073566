import React, { useState } from 'react';
import { Flex, Box, Button, Text } from 'rebass';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Input, Label, Textarea } from '@rebass/forms';
// import { Form } from '@rebass/form';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';

const ajax = (method, url, data, success, error) => {
	const xhr = new XMLHttpRequest();
	xhr.open(method, url);
	xhr.setRequestHeader('Accept', 'application/json');
	xhr.setRequestHeader('Content-type', 'application/json');
	xhr.onreadystatechange = function() {
		if (xhr.readyState !== XMLHttpRequest.DONE) return;
		if (xhr.status === 200) {
			success(xhr.response, xhr.responseType);
		} else {
			error(xhr.status, xhr.response, xhr.responseType);
		}
	};
	xhr.send(data);
};

const validate = ({ email, name }) => {
	let errors = {};

	if (!email) errors.email = 'Adres email jest wymagany';

	if (!name) errors.name = 'Imię jest wymagane';

	return errors;
};

const ContactForm = ({ occupation, name, description }) => {
	return (
		<Formik
			initialValues={{
        name: '',
        email: '',
        phone: '',
        topic: '',
        message: '',
        '*default_email': 'kontakt@lisbud.com.pl',
      }}
			onSubmit={(data, { resetForm }) => {
				ajax(
					'POST',
					'https://www.enformed.io/y988i73p',
					JSON.stringify(data),
					() => {
						resetForm();
					},
					() => {
					}
				);
			}}
			validate={validate}
			render={({ onSubmit, errors, handleChange, values, ...rest }) => (
				<Form py={3}>
					<Flex px={5} flexDirection="column">
						<Box py={1}>
							<Input
								value={values.name || ''}
								placeholder="Imię *"
								id="name"
								onChange={handleChange}
								name="name"
								sx={{
									borderColor: errors.name ? 'red' : 'inherit'
								}}
							/>
						</Box>
						<Box py={1}>
							<Input
								value={values.email || ''}
								placeholder="E-mail *"
								id="email"
								name="email"
								type="email"
								onChange={handleChange}
								sx={{
									borderColor: errors.email ? 'red' : 'inherit'
								}}
							/>
						</Box>
						<Box py={1}>
							<Input
                value={values.phone || ''}
                onChange={handleChange}
								placeholder="Telefon"
								id="phone"
								name="phone"
								type="text"
							/>
						</Box>
						<Box py={1}>
              <Input 
                value={values.topic || ''}
                onChange={handleChange}
                placeholder="Temat"
                id="topic"
                name="topic"
                type="text"
              />
						</Box>
						<Box py={1}>
							<Textarea
                value={values.message || ''}
                onChange={handleChange}
								placeholder="Wiadomość"
								rows="5"
								id="message"
								name="message"
								fontFamily="Arial"
								type="text"
							/>
						</Box>
						<Flex flexWrap="wrap">
							<Flex flexDirection="column" flex="2">
								{Object.values(errors).map((error, id) => (
									<Text key={id} color="red" my={1}>
										{error}
									</Text>
								))}
							</Flex>
							<Box px={2} py={2} ml="auto">
								<Button onClick={onSubmit}>Wyślij</Button>
							</Box>
						</Flex>
					</Flex>
				</Form>
			)}
		/>
	);
};

export default ContactForm;
