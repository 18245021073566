import React from 'react';
import { Heading, Box, Text, Flex, Button } from 'rebass';
import { StaticQuery, graphql } from 'gatsby';
import ServiceElement from '../components/ServiceElement';
import Section from '../components/Section';

const centerHorizontally = { marginRight: 'auto', marginLeft: 'auto' };

export const query = graphql`
	query {
		allContentfulSection(filter: { SectionName: { eq: "Usługi" } }) {
				nodes {
					header
					h3 {
						h3
					}
          SectionName
					servicesContent {
						heading
						description {
							description
						}
						image {
								title
								fluid(maxWidth: 350, quality: 100) {
									sizes
									src
									srcSet
                  srcWebp
                  srcSetWebp
								}
						}
					}
				}
		}
	}
`;

const defaultServices = [
	{
		imageSrc: 'kanalizacje.webp',
		service: 'KANALIZACJA SANITARNA',
		description: 'Skutecznie. Rzetelnie. Najlepiej.'
	},
	{
		imageSrc: 'wodociag.webp',
		service: 'SIECI WODOCIĄGOWE',
		description: 'Chodzi o szczegóły'
	},
	{
		imageSrc: 'przewierty.webp',
		service: 'PRZEWIERTY STEROWANE',
		description: 'Dostosowane do Twoich potrzeb'
	}
];

const About = () => (
  <StaticQuery
    query={query}
    render={({ allContentfulSection, allContentfulSection: {nodes:[{header,
      h3: { h3 }, servicesContent, SectionName }]}}) => {
	return (
  <Section.Container id={SectionName}>
    <Heading
      as="h1"
      color="secondary"
      textAlign="center"
      style={centerHorizontally}
      fontSize={[ 5, 5, 5 ]}
    >
      <Text width={[ 300, 400, 700 ]} key={1} style={centerHorizontally} mb={[ 3, 5, 5 ]}>
        {header}
      </Text>
    </Heading>
    <Heading
      as="h2"
      color="primaryFont"
      textAlign="center"
      style={centerHorizontally}
      fontSize={[ 3, 3, 3 ]}
      mb={[ 4, 3, '58px' ]}
    >
      <Text
        width={[ 300, 400, 780 ]}
        lineHeight="1.875em"
        fontWeight="lighter"
        style={centerHorizontally}
        fontSize={[ 2, 2, 2 ]}
        key={1}
      >
        {h3}
      </Text>
    </Heading>

    <Flex textAlign="center" flexWrap="wrap" justifyContent="center">
      {' '}
      {servicesContent.map((item) => (
        <ServiceElement
          key={item.heading}
          imageSrc={item.image.fluid.src}
          service={item.heading}
          description={item.description.description}
        />
			))}
    </Flex>
  </Section.Container>
)
  }} 
  />
);

export default About;
