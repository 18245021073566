import React, { Fragment } from 'react';
import Headroom from 'react-headroom';
import { StaticQuery, graphql } from 'gatsby';
import { Flex, Box } from 'rebass';
import styled from 'styled-components';
import { SectionLinks } from 'react-scroll-section';
import Img from "gatsby-image"
import Fade from 'react-reveal/Fade';
import RouteLink from './RouteLink';

const capitalize = s => s && s[0].toUpperCase() + s.slice(1);

const HeaderContainer = styled(Headroom)`
  .headroom--pinned {
    color: ${props => props.theme.colors.logoFont};
    background: ${props => props.theme.colors.primaryDark};
  }
  color: ${props => props.theme.colors.logoFont};
  background: ${props => props.theme.colors.primaryDark};
  position: absolute;
  font-weight: normal;
  line-height: 1.41em;
  font-size: 22px;
  z-index: 9999;
  width: 100%;
`;

export const query = graphql`
 query {
    desktopImage: file(
      relativePath: { eq: "lisbud.png" }
    ) {
      childImageSharp {
        fixed(width: 200, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allContentfulCompanyInfo {
      nodes {
        mobileNumber
        telephoneNumber
      }
    }
  }
`

const formatLinks = allLinks =>
  Object.entries(allLinks).reduce(
    (acc, [key, value]) => {
      const isHome = key === 'home';
      return isHome
        ? {
            ...acc,
            home: value,
          }
        : {
            ...acc,
            links: [...acc.links, { name: capitalize(key), value }],
          };
    },
    { links: [], home: null },
  );

const Header = () => (
  <StaticQuery
    query={query}
    render={
    (data) =>  {
      const {allContentfulCompanyInfo: {nodes: [{mobileNumber, telephoneNumber}]}} = data;
      return (
      <HeaderContainer>
        <Fade top>
          <Flex
            flexWrap="wrap"
            justifyContent="space-between"
            alignItems="center"
            p={3}
            pb={2}
          >
            <SectionLinks>
              {({ allLinks }) => {
            const { home, links } = formatLinks(allLinks);

            const homeLink = home && (
              <>
                <Box>
                  <Img fixed={data.desktopImage.childImageSharp.fixed} />
                </Box>
              </>
              // <p>ZAKŁAD INSTALACYJNO BUDOWLANY LISBUD PAWEŁ LISOWSKI</p>
            );
            const navLinks = links.map(({ name, value }) => (
              <RouteLink
                key={name}
                onClick={value.onClick}
                selected={value.selected}
                name={name}
              />
            ));

            return (
              <Fragment>
                {homeLink}
                <Flex mr={[0, 3, 5]}>{navLinks}</Flex>
              </Fragment>
            );
          }}
            </SectionLinks>
          </Flex>
          <Flex
            // flexDirection="column"
            justifyContent="flex-end"
            backgroundColor="secondary"
            fontSize={2}
            px={3}
          >
            {/* <StripedBox /> */}
            <Box fontSize={2} lineHeight={2} mr={[0, 3, 5]} color="primary">
      ✆ {telephoneNumber}  📱  {mobileNumber}
            </Box>
          </Flex>
        </Fade>
      </HeaderContainer>
)}}
  
  />
 
);

export default Header;
