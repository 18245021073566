import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image"
import Fade from 'react-reveal/Fade';
import { Heading, Text } from 'rebass';
import Section from '../components/Section';

const centerHorizontally = { marginRight: 'auto', marginLeft: 'auto' };

export const query = graphql`
 query {
    mobileImage: file(relativePath: { eq: "b1.jpg" }) {
      childImageSharp {
        fixed(width: 428, height: 637, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    desktopImage: file(
      relativePath: { eq: "a1.jpg" }
    ) {
      childImageSharp {
        fixed(height: 470, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allContentfulSection(filter: { SectionName: { eq: "Info" } }) {
			edges {
				node {
					header
					h3 {
						h3
					}
          SectionName
				}
			}
		}
  }
`

const Background = ({ file }) => (
  <Img fixed={file} />
);


const LandingPage = () => (
  <StaticQuery
    query={query}
    render={({allContentfulSection: {edges: [{node} = {}] = []}, ...data}) => {
      const { header, SectionName, h3: { h3 } = {} } = node || {};
      const sources = [
       data.mobileImage.childImageSharp.fixed,
        {
          ...data.desktopImage.childImageSharp.fixed,
          media: `(min-width: 768px)`,
          width: '100%',
        },
      ]
      return (
        <Section.Container
          file={sources}
          id={SectionName}
          Background={Background} 
          sectionStyles={{marginBottom: '-5px'}}
          containerStyles={{minHeight: 'auto', padding: '1em 1em', position: 'absolute', top: '0', right: '0', left: '0'}}
        >
          <Fade top cascade>
            <Heading
              as="h1"
              color="primaryFont"
              textAlign="center"
              fontSize={[4, 5, 6]}
              style={centerHorizontally}
              mt={[0, 5, 5]}
              mb={[2, 3, 3]}
            >
              <Text
                width={[300, 500]} 
                key={1}
                style={centerHorizontally}
                mb={[3, 4, 4]}
                fontSize={[5, 5, 5]}
              >
                {header}
              </Text>
            </Heading>
            <Heading
              as="h2"
              color="primaryFont"
              textAlign="center"
              style={centerHorizontally}
              fontSize={[3, 4, 5]}
              mb={[4, 3, '58px']}
            >
              <Text
                width={[300, 500, 780]}
                fontSize={[2, 2, 2]}
                style={centerHorizontally}
                letterSpacing="normal"
                lineHeight="1.875em"
                fontWeight="normal"
                key={2}
              >
              {h3}
              </Text>
            </Heading>
          </Fade>
        </Section.Container>
  )}}
  />
);

export default LandingPage;
