import React, { Fragment } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Img from 'gatsby-image';
import { Heading, Image, Flex, Box, Text } from 'rebass';
import Section from '../components/Section';

const centerHorizontally = { marginRight: 'auto', marginLeft: 'auto' };

export const query = graphql`
	query {
		mobileImage: file(relativePath: { eq: "landing_mobile.webp" }) {
			childImageSharp {
				fluid(maxWidth: 500, quality: 100) {
					...GatsbyImageSharpFluid
				}
			}
		}
		desktopImage: file(relativePath: { eq: "landing.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 2000, quality: 100) {
					...GatsbyImageSharpFluid
				}
			}
		}
		allContentfulSection(filter: { SectionName: { eq: "landing" } }) {
			edges {
				node {
					header
					h2
					h3 {
						h3
					}
				}
			}
		}
	}
`;

const Background = ({ file }) => {
	const sources = [
		file.mobileImage.childImageSharp.fluid,
		{
			...file.desktopImage.childImageSharp.fluid,
			media: `(min-width: 768px)`
		}
	];
	return (
  <Box
    css={{
				'@media screen and (max-width: 464px)': {
					'padding-top': '137px'
				}
			}}
    pt={[ '106px', '106px', '74px' ]}
		>
    <Img fluid={sources} />
  </Box>
	);
};

const LandingPage = () => (
  <StaticQuery
    query={query}
    render={({ allContentfulSection: {edges: [{node}]}, ...data }) => {
			const { header, h2, h3: { h3 } = {} } = node || {};
			return (
  <Section.Container
    id="home"
    file={data}
    Background={Background}
    containerStyles={{ minHeight: 'auto' }}
  >
    <Fade bottom cascade>
      <Heading textAlign="center" key={1} as="h1" color="secondary" fontSize={[ 5, 6, 8 ]} mb={[ 3, 4, 4 ]}>
        {header}
      </Heading>
      <Heading
        as="h2"
        color="primaryFont"
        textAlign="center"
        fontSize={[ 4, 5, 6 ]}
        style={centerHorizontally}
        mb={[ 4, 3, '58px' ]}
        key={2}
      >
        <Text
          width={[ 300, 500, 700 ]}
          key={1}
          fontWeight="normal"
          style={centerHorizontally}
          mb={[ 3, 4, 4 ]}
          fontSize={[ 3, 3, 3 ]}
        >
          {h2}
        </Text>
        <Text
          width={[ 300, 500, 780 ]}
          fontSize={[ 2, 2, 2 ]}
          letterSpacing="normal"
          lineHeight="1.875em"
          fontWeight="normal"
          style={centerHorizontally}
          key={2}
        >
          {h3}
        </Text>
      </Heading>
    </Fade>
  </Section.Container>
			);
		}}
  />
);

export default LandingPage;
